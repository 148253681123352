<template>
  <a-layout class="welcome">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-spin :spinning="loading" :tip="labels.loading">
        <span class="html-content" v-if="!loading && HTML" v-html="HTML" />
        <div class="action-container">
          <router-link :to="{ name: 'buy' }" class="btn-play-now">
            <a-button type="primary" size="large" shape="round">
              {{ labels.playNow }}
            </a-button>
          </router-link>
        </div>
      </a-spin>
    </div>
  </a-layout>
</template>
<script>
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
import { mapGetters, mapState } from "vuex";
import { mapActions } from "vuex/dist/vuex.common.js";
export default {
  components: { TitleBreadcrumb },
  name: "WelcomePage",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.welcomePage.title),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.welcomePage.description,
      },
    ],
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      if (
        (vm.isFromRegister && from.name === "register") ||
        from.name === "verify-phone"
      ) {
        next();
      } else {
        if (from.name) {
          next({ name: from.name });
        } else {
          if (vm.authenticated) next({ name: "home" });
          else next({ name: "login" });
        }
      }
    });
  },
  data() {
    return {
      labels: labels.welcomePage,
      HTML: null,
      loading: false,
    };
  },
  created() {
    this.handleFetchWelcomePageHTML();
  },
  computed: {
    ...mapState("clientAuth", ["authenticated"]),
    ...mapGetters("clientAuth", ["isFromRegister"]),
  },
  methods: {
    ...mapActions("systemConfig", ["fetchWelcomePageHTML"]),
    async handleFetchWelcomePageHTML() {
      try {
        this.loading = true;
        const { data } = await this.fetchWelcomePageHTML();
        this.HTML = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  beforeDestroy() {
    this.HTML = null;
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.welcome {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  .content-base.container {
    padding-top: 20px !important;
  }
  ::v-deep .price-list-container {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    .price-list-item-title h3 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      color: color(--white) !important;
    }
    table.price-list-item-content-table {
      min-width: 310px;
      border-collapse: collapse;
      border-spacing: 0;
      thead {
        tr {
          th {
            padding: 10px;
            text-align: left;
            border: 1px solid color(--white);
            color: color(--white) !important;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            text-align: left;
            border: 1px solid color(--white);
            color: color(--white) !important;
          }
          &:nth-child(odd) {
            background-color: color(c-secondary);
          }
        }
      }
    }
  }
  ::v-deep .deposit-account-list-container {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
    .deposit-account-list-item {
      display: inline-block;
      width: 240px;
      padding: 12px 15px;
      border: 1px solid color(border-color);
      box-shadow: 0 2px 4px 0 color(border-color);
      border-radius: 5px;
      position: relative;
      background-color: color(--white) !important;
      color: black;
      line-height: 1.5;
      &-controls {
        text-align: right;
        position: absolute;
        top: 8px;
        right: 8px;
        .edit-btn {
          color: color(primary);
          font-size: 16px;
          margin-right: 10px;
        }
        .delete-btn {
          color: color(error);
          font-size: 16px;
        }
      }
    }
  }
  ::v-deep .action-container {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .ant-btn-primary {
      background-color: color(c-primary) !important;
      border-color: color(c-hover) !important;
      color: color(c-hover) !important;
      &:hover {
        background-color: color(c-hover) !important;
        border-color: color(c-hover) !important;
        color: color(c-primary) !important;
      }
    }
  }
  ::v-deep .html-content > * {
    color: color(--white);
  }
}
</style>
